export const projectsInfo = [
	{
		title: "Your-md-links",
		img: "https://github.com/KarenAmicone/portafolio/blob/master/src/assets/Your-md-links.png?raw=true",
		description:
			"your-md-links is a NPM package for finding markdown links and their status.",
		repo: "https://github.com/KarenAmicone/CDMX007-fe-md-links",
		demo: "https://www.npmjs.com/package/your-md-links",
		tools: ["node", "npm"],
	},
	{
		title: "Burger Queen",
		id: "burger",
		img: "https://github.com/KarenAmicone/portafolio/blob/master/src/assets/burger_queen.png?raw=true",
		description: "Web application to take orders from a hamburger restaurant.",
		repo: "https://github.com/KarenAmicone/Burger-Queen",
		demo: "https://burger-queen-3c206.firebaseapp.com/",
		tools: ["react", "redux", "firebase", "css"],
	},
	{
		title: "Moodvie",
		img: "https://github.com/KarenAmicone/portafolio/blob/master/src/assets/moodvie.png?raw=true",
		description: "Website that recommends movies based on your mood.",
		repo: "https://github.com/KarenAmicone/Peliculas-entomatadas",
		demo: "https://karenamicone.github.io/Peliculas-entomatadas/src/",
		tools: ["js", "html", "css"],
	},
	{
		title: "Hipatia Project",
		img: "https://github.com/KarenAmicone/portafolio/blob/master/src/assets/hipatia_project.png?raw=true",
		description:
			"World Bank data on the gender labor gap in some Latin American countries.",
		repo: "https://github.com/KarenAmicone/cdmx-2019-01-bc-core-data-lovers",
		demo: "https://karenamicone.github.io/cdmx-2019-01-bc-core-data-lovers/src/",
		tools: ["js", "html", "css"],
	},
];
