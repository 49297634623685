import React from "react";
import { FormInput } from "../../components/Input/input";
import sky from "../../assets/sky.jpg";
import woman from "../../assets/woman.svg";
import DevSocialMedia from "../../components/devSocialMedia";
import "./style.css";

const Contact = () => {
	return (
		<div className="contact-wrapper">
			<section className="secondary-background contact-section">
				<form
					className="contact-container"
					action="https://formsubmit.co/karen_amicone@hotmail.com"
					method="POST"
				>
					<h2>Contact me</h2>
					<div className="input-container">
						<label>Email*</label>
						<FormInput type="email" name="email" required />
					</div>
					<div className="input-container">
						<label>Name*</label>
						<FormInput type="text" name="name" required />
					</div>
					<div className="input-container" name="message">
						<label>Message</label>
						<textarea name="message"></textarea>
					</div>
					<button className="neumorphic button" type="submit">
						Send
					</button>
				</form>
				<div className="contact-image-container">
					<img alt="Sky" src={sky} className="sky"></img>
					<img
						alt="Woman with plants and sun"
						src={woman}
						className="woman"
					></img>
				</div>
			</section>
			<DevSocialMedia />
		</div>
	);
};

export default Contact;
