import React from "react";
import "./style.css";

export const FormInput = ({ type, required, placeholder, name }) => {
	return (
		<input
			placeholder={placeholder}
			required={required}
			type={type}
			name={name}
		></input>
	);
};
