import * as React from "react";
import Codepens from "../../components/codepens";
import DevSocialMedia from "../../components/devSocialMedia";
import { DevTools } from "../../components/DevTools/devTools";

import "./style.css";

function Home() {
	return (
		<section className="home">
			<div className="home-hero-container">
				<h1 className="fade-in light-color-text">Web Developer</h1>
				<p className="fade-in light-color-text">
					React, Next JS, Javascript, HTML, CSS
				</p>
			</div>
			<div className="no-pointer-events"></div>
			<div className="scrollable">
				<div className="main-container">
					<div className="home-welcome-container">
						<p>
							Hi! Welcome to my website.
							<span role="img" aria-label="Hello emoji">
								👋🏼
							</span>
						</p>
						<p>
							My name is Karen Amicone and I'm a frontend developer.{" "}
							<span role="img" aria-label="Female developer emoji">
								👩🏻‍💻
							</span>
						</p>
						<p>
							I have experience developing with Javascript, React and Next Js.
						</p>
						<p>
							What I like most about frontend development is making magic with
							CSS, although I also love logic.
						</p>
						<p>
							Some of my hobbies are reading
							<span role="img" aria-label="Book emoji">
								{" "}
								📖
							</span>
							, dancing{" "}
							<span role="img" aria-label="Dancer emoji">
								💃🏻
							</span>
							, sewing{" "}
							<span role="img" aria-label="Sewing emoji">
								🧵
							</span>
							, embroidery{" "}
							<span role="img" aria-label="Niddle emoji">
								🪡
							</span>
							, and swimming{" "}
							<span role="img" aria-label="Swimming emoji">
								🏊‍♀️
							</span>
							.
						</p>
					</div>
					<Codepens />
				</div>
				<DevTools />
				<DevSocialMedia />
			</div>
		</section>
	);
}

export default Home;
