import React from "react";
import Card from "../../components/Card/card";
import DevSocialMedia from "../../components/devSocialMedia";
import { projectsInfo } from "./projects_info";
import "./styless.css";

const Projects = () => {
	return (
		<section className="work">
			<article className="intro-work">
				<h1 className="section-title">Projects</h1>
				<p>
					These are some of the personal projects I have worked on since I
					started developing and with which I have learned to use different
					technologies.
				</p>
			</article>
			<div className="container secondary-background">
				<div className="cards-container">
					{projectsInfo.map((project) => (
						<div className="project neumorphic" key={project.title}>
							<Card
								title={project.title}
								description={project.description}
								image={project.img}
								linkDemo={project.demo}
								linkRepo={project.repo}
								tools={project.tools}
							/>
						</div>
					))}
				</div>
			</div>
			<DevSocialMedia />
		</section>
	);
};

export default Projects;
