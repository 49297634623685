import * as React from "react";
import "./App.css";
import Home from "./pages/Home/Home";
/* import About from "./pages/About"; */
import Projects from "./pages/Projects/Projects";
import Contact from "./pages/Contact/Contact";

import { Route, Routes } from "react-router-dom";
import ScrollToTop from "./components/scrollToTop";
import { NavBar } from "./components/Navbar/navbar";

function App() {
	return (
		<div>
			<ScrollToTop>
				<NavBar />
				<Routes>
					<Route exact path="/" element={<Home />} />
					{/* <Route path="/about" element={<About />} /> */}
					<Route path="/projects" element={<Projects />} />
					<Route path="/contact" element={<Contact />} />
				</Routes>
			</ScrollToTop>
		</div>
	);
}

export default App;
