import React from "react";
import {
	SiCss3,
	SiFirebase,
	SiHtml5,
	SiJavascript,
	SiNodedotjs,
	SiNpm,
	SiReact,
	SiRedux,
} from "react-icons/si";
import "./styles.css";

const Card = ({ title, description, image, linkDemo, linkRepo, tools }) => {
	const techIcons = [
		{ name: "node", icon: <SiNodedotjs className="tools" /> },
		{ name: "npm", icon: <SiNpm className="tools" /> },
		{ name: "js", icon: <SiJavascript className="tools" /> },
		{ name: "html", icon: <SiHtml5 className="tools" /> },
		{ name: "css", icon: <SiCss3 className="tools" /> },
		{ name: "react", icon: <SiReact className="tools" /> },
		{ name: "redux", icon: <SiRedux className="tools" /> },
		{ name: "firebase", icon: <SiFirebase className="tools" /> },
	];
	const projectTechnologies = techIcons.filter((icon) =>
		tools.includes(icon.name)
	);

	return (
		<div className="card">
			<div style={{ display: "flex", justifyContent: "space-between" }}>
				<div style={{ width: "60%" }}>
					<h3 className="card-title">{title}</h3>
					<p className="card-description">{description}</p>
				</div>
				<div className="card-image">
					<img alt={title} src={image}></img>
				</div>
			</div>
			<div className="card-tools-container">
				<p style={{ fontSize: "16px", fontWeight: "bold" }}>Tech stack:</p>{" "}
				{projectTechnologies.map((tool) => tool.icon)}
			</div>
			<div className="card-buttons-container">
				<a
					className="card-button neumorphic"
					href={linkRepo}
					target="_blank"
					rel="noopener noreferrer"
				>
					Repository
				</a>
				<a
					className="card-button neumorphic"
					href={linkDemo}
					target="_blank"
					rel="noopener noreferrer"
				>
					Demo
				</a>
			</div>
		</div>
	);
};

export default Card;
