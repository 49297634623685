import * as React from "react";
import { AiOutlineHome } from "react-icons/ai";
import {
	MdContactMail,
	MdOutlineWorkOutline /* , MdOutlinePersonOutline */,
} from "react-icons/md";

import { Link } from "react-router-dom";
import "./style.css";

export const NavBar = () => {
	return (
		<nav className="navbar">
			<p>Karen Amicone</p>
			<ul className="nav navbar-nav">
				<li>
					<Link to="/">
						<AiOutlineHome className="mobile-element" />
						<p className="desktop-element">Home</p>
					</Link>
				</li>
				{/* <li>
					<Link to="/about">
						<MdOutlinePersonOutline className="mobile-element" />
						<p className="desktop-element">About me</p>
					</Link>
				</li> */}
				<li>
					<Link to="/projects">
						<MdOutlineWorkOutline className="mobile-element" />
						<p className="desktop-element">Projects</p>
					</Link>
				</li>
				<li>
					<Link to="/contact">
						<MdContactMail className="mobile-element" />
						<p className="desktop-element">Contact</p>
					</Link>
				</li>
			</ul>
		</nav>
	);
};
