import React from "react";
import { SiGithub, SiLinkedin } from "react-icons/si";

function DevSocialMedia() {
	const date = new Date();
	const year = date.getFullYear();

	return (
		<div className="dev-social-media">
			<div className="social-media-container">
				<p>Visit my profile</p>
				<a
					className="social-media-icon"
					href="https://www.linkedin.com/in/karen-amicone/"
					target="_blank"
					rel="noopener noreferrer"
				>
					<SiLinkedin fontSize="2rem" />
				</a>
				<a
					className="social-media-icon"
					href="https://github.com/KarenAmicone"
					target="_blank"
					rel="noopener noreferrer"
				>
					<SiGithub fontSize="2rem" />
				</a>
			</div>
			<p>{year}</p>
		</div>
	);
}

export default DevSocialMedia;
