import * as React from "react";
import "./style.css";
import {
	SiNextdotjs,
	SiReact,
	SiJavascript,
	SiMongodb,
	SiGoogleanalytics,
	SiGoogletagmanager,
	SiGithub,
	SiGitlab,
	SiCss3,
} from "react-icons/si";

export const DevTools = () => {
	const toolsArr = [
		{ icon: <SiNextdotjs />, label: "Next JS" },
		{ icon: <SiReact />, label: "React JS" },
		{ icon: <SiJavascript />, label: "Javascript" },
		{ icon: <SiMongodb />, label: "Mongo DB" },
		{ icon: <SiGoogleanalytics />, label: "Google Analytics" },
		{ icon: <SiGoogletagmanager />, label: "Google Tag Manager" },
		{ icon: <SiGithub />, label: "Github" },
		{ icon: <SiGitlab />, label: "Gitlab" },
		{ icon: <SiCss3 />, label: "CSS" },
	];

	return (
		<div className="tools-main-container secondary-background">
			<h2>Dev tools</h2>
			<div className="tools-container">
				{toolsArr.map((tool) => (
					<div className="tool-icon">
						{tool.icon} <p>{tool.label}</p>
					</div>
				))}
			</div>
		</div>
	);
};
