import React from "react";

function Codepens() {
	return (
		<div className="home-codepens-container secondary-background">
			<h2>Codepen creations</h2>
			<iframe
				height="400"
				style={{
					width: "90%",
					borderRadius: "20px",
					boxShadow: "3px 3px 6px #b8b9be, -3px -3px 6px #fff",
				}}
				scrolling="no"
				title="Fireflies floating and shining"
				src="https://codepen.io/Karenamicone/embed/KKPEwJO?default-tab=result&theme-id=dark"
				frameBorder="no"
				loading="lazy"
				allowtransparency="true"
				allowFullScreen={true}
			></iframe>
			<a
				className="neumorphic button"
				href="https://codepen.io/Karenamicone"
				rel="noopener noreferrer"
				target="_blank"
			>
				See more
			</a>
		</div>
	);
}

export default Codepens;
